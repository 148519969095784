<template>

  <section class="landing-section">
    <div class="landing-section_hero-diamond" :style="{ backgroundImage: 'url(' + background + ')'}">
      <div class="grid-container-home">
        <div class="texAndLogo_content">

          <div class="textAndLogo__content-left">
            <h2 class="textAndLogo_block-text__title">The team</h2>
            <transition appear
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @after-enter="afterEnter">
              <p class="textAndLogo__bottom-text">coffee, friendship and hard work</p>
            </transition>
          </div>

          <div class="textAndLogo__content-right">
            <img :src="logo" alt="Scriptics Decisions" class="floating-image">
            <img :src="logo" alt="Scriptics Decisions" class="static-image">
          </div>

        </div>
      </div>
    </div>

    <!--    <img id="triangles-hero-compound" :src="hero" alt="Hero">-->
    <!--    <img id="triangles-hero-1" :src="hero1" alt="Hero1">-->
    <!--    <img id="triangles-hero-duo" :src="heroDuo" alt="HeroDuo">-->
    <!--    <img id="trianglesHero3" :src="hero3" alt="Hero3">-->

    <TeamSlider></TeamSlider>
<!--    <div class="team-portraits">-->
<!--      <div v-for="(employee, index) in allEmployees" :key="index" class="individual">-->
<!--        <img :src="`${link}${employee.over_picture}`" alt="employee">-->
<!--      </div>-->
<!--    </div>-->
    <ApplyNow></ApplyNow>
    <Footer color="#181818">
      <DropDownFooter colorMobile="#181818">
        <DropDown subMenuColor="#181818"></DropDown>
      </DropDownFooter>
    </Footer>
  </section>

</template>


<script>
import hero from '@/assets/hero-diamond-flume.webp';
import TeamSlider from "../components/Team/TeamSlider";
import DropDownFooter from "@/components/FooterMobile/DropDownFooter";
import DropDown from "@/components/FooterMobile/DropDown";
import ApplyNow from "@/components/Team/ApplyNow";
import Footer from "../components/Home/Footer";
import Scriptics from '@/assets/scriptics-s20.svg';
// import trianglesHeroCompound from '@/assets/triagnles-hero-compound.svg';
// import triangnlesHero from '@/assets/triagnles-hero-pc1.svg';
// import triangnlesHeroDuo from '@/assets/triagnles-hero-pc2.svg';
// import triangnleshero3 from '@/assets/triagnles-hero-pc3.svg';
import gsap from 'gsap';
import {mapActions, mapGetters} from "vuex";
import {CMS} from "@/main";

export default {
  name: 'Team',
  components: {DropDown, DropDownFooter, ApplyNow, TeamSlider, Footer},
  computed: mapGetters(["allEmployees"]),

  data() {
    return {
      link: CMS,
      company: 'Scriptics',
      messageCompany: 'A creative company like no other',
      background: hero,
      logo: Scriptics,
      // hero: trianglesHeroCompound,
      // hero1: triangnlesHero,
      // heroDuo: triangnlesHeroDuo,
      // hero3: triangnleshero3,
    }
  },
  methods: {
    ...mapActions(['loadEmployees']),

    beforeEnter: function (el) {
      //  console.log('before enter - set initial state');
      el.style.transform = 'translateY(-90px)'
      el.style.opacity = 0
    },
    enter: function (el, done) {
      //console.log('starting to enter - make transition')
      gsap.to(el, {
        duration: 3,
        y: 0,
        opacity: 1,
        ease: 'bounce.out',
        onComplete: done, //tells on vue component that is done with this stage and go to the next stage
      })
    },
    afterEnter: function () {
      //   console.log('after enter'); //after 3 sec
    }
  },

  created() {
    this.loadEmployees();
  },
}

</script>

<style scoped lang="scss">

.team-portraits {
  height: auto;
  width: 100%;
  position: relative;
  color: white;
  background-color: white;
  overflow: hidden;
  padding: 1%;
  text-align: center;
  justify-content: center;

  .individual{
    display: inline-flex;

    img {
      height: 100px;
      width: auto;
    }
  }

}

.landing-section {
  display: block;
  max-height: 100vh;
  position: relative;
  width: 100%;
  background: linear-gradient(242deg, #2c4d58 21%, #000000 53%);
}

.landing-section_hero-diamond {
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: 50%;
}

.texAndLogo_content {
  padding-top: 45em;
}

.grid-container-home {
  width: 92%;
  margin: 0 auto;
  overflow: hidden;
  height: 100vh;
}

.textAndLogo__content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: left;
  position: relative;
  left: 0;
}

.textAndLogo_block-text__title {
  font-size: 9em;
  background: rgb(47, 152, 200);
  background: linear-gradient(125deg, #d3e5ed 50%, #8caee7 72%, #e398d6 82%);
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1em;
  font-weight: 900;
  text-align: right;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

}

.textAndLogo_block-text__title,
.textAndLogo__bottom-text {
  font-family: "Heebo", sans-serif;
  z-index: 2;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.textAndLogo__bottom-text {
  font-size: 2.4em;
  background: rgb(47, 152, 200);
  background: linear-gradient(125deg, #d3e5ed 50%, #8caee7 72%, #e398d6 82%);
  font-weight: 900;
  text-align: right;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textAndLogo_block-text__title,
.textAndLogo__bottom-text {
  font-weight: 900;
  text-align: right;
}

.textAndLogo__content-right {
  float: right;
  width: calc(27% - 2px);
  zoom: 200%;
  position: relative;
  bottom: 108px;
  z-index: 3;
  overflow: visible;

  .floating-image {
    transform: translatey(0px);
    animation: float 4s ease-in-out infinite;
  }

  .static-image {
    position: absolute;
    left: 0;
    opacity: 0.5;
  }

}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

//#triangles-hero-compound {
//  position: absolute;
//  height: 100em;
//  width: calc(60% - 2px);
//  top: 3%;
//  left: 40%;
//}
//
//#triangles-hero-1 {
//  position: absolute;
//  height: 25%;
//  top: 0;
//  left: -1%;
//}
//
//#triangles-hero-duo {
//  position: absolute;
//  height: 35%;
//  top: -3%;
//  left: 26%;
//}
//
//#trianglesHero3 {
//  position: absolute;
//  height: 28%;
//  top: 100em;
//  left: 68%;
//  z-index: 2;
//}

@media screen and (max-width: 1990px) {
  .textAndLogo__content-right {
    width: calc(27% - 2px);
    zoom: 150%;
    position: relative;
    bottom: 200px;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 184px;
  }

  #triangles-hero-compound {
    position: absolute;
    display: block;
    height: 66em;
    left: 40%;
    top: 14%
  }
  #triangles-hero-1 {
    height: 25%;
  }
  #triangles-hero-duo {
    position: absolute;
    height: 40%;
    top: 0;
    left: 29%;
  }
  #trianglesHero3 {
    position: absolute;
    top: 73em;
    left: 71%;
  }
}

@media screen and (max-width: 1440px) {
  .textAndLogo__content-right {
    zoom: 130%;
    width: calc(27% - 2px);
    position: relative;
    bottom: 360px;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 275px;
  }
  #triangles-hero-compound {
    position: absolute;
    display: block;
    height: 55em;
    top: 16%;
    left: 40%;
  }

  #trianglesHero3 {
    position: absolute;
    height: 25%;
    top: 67em;
    left: 67%;
  }
  #triangles-hero-duo {
    position: absolute;
    height: 40%;
    top: 0;
    left: 24%;
  }
}

@media screen and (max-width: 1330px) {
  .textAndLogo__content-right {
    zoom: 130%;
    width: calc(25% - 2px);
    bottom: 371px;
  }
}

@media screen and (max-width: 1024px) {
  .landing-section_hero-diamond {
    background-position: 77%;
  }
  .textAndLogo_block-text__title {
    font-size: 6em;
  }
  .textAndLogo__bottom-text {
    font-size: 1.6em;
  }
  .textAndLogo__content-right {
    width: calc(31% - 2px);
    position: relative;
    bottom: 414px;
    zoom: 120%;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 371px;
  }
  #triangles-hero-compound {
    position: absolute;
    display: block;
    top: -4%;
    left: 37%;
    transform: scale(1.12);
  }
  #triangles-hero-1 {
    position: absolute;
    height: 15%;
  }

  #triangles-hero-duo {
    position: absolute;
    height: 29%;
    left: 15%;
  }

  #trianglesHero3 {
    position: absolute;
    height: 14em;
    top: 48em;
    left: 61%;;
  }

}

@media screen and (max-width: 768px) {

  .textAndLogo__content-right {
    width: calc(30% - 2px);
    position: relative;
    bottom: 611px;
    zoom: 95%;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 220px;
  }
  #triangles-hero-compound {
    position: absolute;
    height: 26em;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    top: 45px;
    left: 39%;
  }
  #triangles-hero-1 {
    position: absolute;
    height: 15%;
  }

  #triangles-hero-duo {
    position: absolute;
    height: 25%;
    left: 19%;
    top: -20px;
  }

  #trianglesHero3 {
    position: absolute;
    height: 20%;
    top: 35em;
    left: 62%;
  }
  .textAndLogo_block-text {
    margin-top: 15em;
  }

  .textAndLogo_block-text__title {
    font-size: 5em;
  }

  .textAndLogo__bottom-text {
    font-size: 21px;
  }
}

@media screen and (max-width: 425px) {

  .landing-section_hero-diamond {
    -webkit-background-size: cover;
    background-position: center;
  }

  .texAndLogo_content {
    padding-top: 53em;
  }

  .textAndLogo__content-right {
    position: relative;
    zoom: 120%;
    bottom: 620px;

    .static-image {
      left: 50%;
      transform: translateX(-50%);
    }

  }

  .textAndLogo__content-left,
  .textAndLogo__content-right {
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .textAndLogo__content-left {
    left: 0;
    bottom: 300px;
  }

  #triangles-hero-compound {
    -o-object-fit: cover;
    object-fit: cover;
    top: 32%;
    left: 8%;
    width: 140px;
    -webkit-transform: scale(4.5);
    transform: scale(4.5);
    height: 12em;
  }

  #triangles-hero-1 {
    display: none;
  }

  #triangles-hero-duo {
    display: none;
  }

  #trianglesHero3 {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  #triangles-hero-compound {
    left: -1%;
    width: 138px;
  }
}

@media screen and(max-width: 330px) {
  #triangles-hero-compound {
    top: 27%;
    left: -18%;
  }
  .texAndLogo_content {
    padding-top: 51em;
  }
  .textAndLogo_block-text__title {
    font-size: 4.5em;
  }
  .textAndLogo__bottom-text {
    font-size: 19px;
  }
}
</style>


<!--<template>-->
<!--  <div class="team-section-page">-->
<!--    <div class="hero-team" :style="{ backgroundImage: 'url(' + backgroundHeroTeam + ')'}">-->
<!--      <div class="container-info-generic">-->

<!--        <div class="ext-bg-overlay">-->
<!--          <div class="romb div1"></div>-->
<!--          <div class="div2 romb"></div>-->
<!--          <div class="div3 romb"></div>-->
<!--          <div class="div4 romb"></div>-->
<!--          <div class="div5 romb"></div>-->
<!--        </div>-->


<!--        <div class="container-left">-->
<!--          <div class="container-left-swipe">-->
<!--            <h4 class="container-left-swipe_title">The team</h4>-->
<!--          </div>-->
<!--          <div class="container-left-swipe container-left-swipe&#45;&#45;delay">-->
<!--            <p class="container-left-swipe_subtitle">coffee, friendship and hard work</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--    <TeamSlider></TeamSlider>-->
<!--    <ApplyNow></ApplyNow>-->
<!--    <Footer color="#181818">-->
<!--      <DropDownFooter colorMobile="#181818">-->
<!--        <DropDown subMenuColor="#181818"></DropDown>-->
<!--      </DropDownFooter>-->
<!--    </Footer>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--/* eslint-disable */-->
<!--import heroteam from '@/assets/team/hero-team-img.webp';-->
<!--import TeamSlider from "../components/Team/TeamSlider";-->
<!--import Footer from "../components/Home/Footer";-->
<!--import gsap from "gsap";-->
<!--import ScrollTrigger from "gsap/ScrollTrigger";-->
<!--gsap.registerPlugin(ScrollTrigger);-->


<!--import ApplyNow from "../components/Team/ApplyNow";-->
<!--import $ from "jquery";-->
<!--import DropDownFooter from "../components/FooterMobile/DropDownFooter";-->
<!--import DropDown from "../components/FooterMobile/DropDown";-->


<!--export default {-->
<!--  name: 'Team',-->
<!--  components: { DropDown, DropDownFooter, ApplyNow, TeamSlider, Footer},-->
<!--  data() {-->
<!--    return {-->
<!--      backgroundHeroTeam: heroteam,-->
<!--    }-->
<!--  },-->
<!--  mounted() {-->
<!--    $(document).ready(function () {-->
<!--      animateDiv();-->
<!--      animateDiv2();-->
<!--      animateDiv3();-->
<!--      animateDiv4();-->
<!--      animateDiv5();-->

<!--    });-->
<!--    // parallax effect on the header img-->
<!--    gsap.to('.hero-team .container-info-generic', {-->
<!--      y: '80%',-->
<!--      ease: Power0.easeNone,-->
<!--      scrollTrigger: {-->
<!--        trigger: '.hero-team',-->
<!--        start: 'top 1px',-->
<!--        end: 'bottom 100px',-->
<!--        scrub: true,-->
<!--        markers: false,-->
<!--      },-->
<!--    });-->

<!--    gsap.to('.hero-team .container-info-generic', {-->
<!--      filter: 'brightness(0.25) blur(16px)',-->
<!--      ease: Power0.easeNone,-->
<!--      scrollTrigger: {-->
<!--        trigger: '.hero-team',-->
<!--        start: 'center top',-->
<!--        scrub: true,-->
<!--      },-->
<!--    });-->

<!--    // the header title-->
<!--    gsap.to('.hero-team .container-left-swipe_title', {-->
<!--      y: '99vh',-->
<!--      ease: Power0.easeNone,-->
<!--      scrollTrigger: {-->
<!--        trigger: '.hero-team',-->
<!--        start: '25% top',-->
<!--        scrub: true,-->
<!--      }-->
<!--    });-->

<!--    function makeNewPosition() {-->


<!--      var h = $(window).height();-->
<!--      var w = $(window).width();-->

<!--      var nh = Math.floor(Math.random() * h);-->
<!--      var nw = Math.floor(Math.random() * w);-->

<!--      return [nh, nw];-->

<!--    }-->

<!--    function animateDiv() {-->
<!--      var newq = makeNewPosition();-->
<!--      var oldq = $('.ext-bg-overlay .div1').offset();-->
<!--      var speed = calcSpeed([oldq.top, oldq.left], newq);-->

<!--      $('.ext-bg-overlay .div1').animate({top: newq[0], left: newq[1]}, speed, function () {-->
<!--        animateDiv();-->
<!--      });-->

<!--    }-->

<!--    function animateDiv5() {-->
<!--      var newq = makeNewPosition();-->
<!--      var oldq = $('.ext-bg-overlay .div5').offset();-->
<!--      var speed = calcSpeed([oldq.top, oldq.left], newq);-->

<!--      $('.ext-bg-overlay .div5').animate({top: newq[0], left: newq[1]}, speed, function () {-->
<!--        animateDiv5();-->
<!--      });-->

<!--    }-->

<!--    function animateDiv4() {-->
<!--      var newq = makeNewPosition();-->
<!--      var oldq = $('.ext-bg-overlay .div4').offset();-->
<!--      var speed = calcSpeed([oldq.top, oldq.left], newq);-->

<!--      $('.ext-bg-overlay .div4').animate({top: newq[0], left: newq[1]}, speed, function () {-->
<!--        animateDiv4();-->
<!--      });-->

<!--    }-->

<!--    function animateDiv3() {-->
<!--      var newq = makeNewPosition();-->
<!--      var oldq = $('.ext-bg-overlay .div3').offset();-->
<!--      var speed = calcSpeed([oldq.top, oldq.left], newq);-->

<!--      $('.ext-bg-overlay .div3').animate({top: newq[0], left: newq[1]}, speed, function () {-->
<!--        animateDiv3();-->
<!--      });-->

<!--    }-->

<!--    function animateDiv2() {-->
<!--      var newq = makeNewPosition();-->
<!--      var oldq = $('.ext-bg-overlay .div2').offset();-->
<!--      var speed = calcSpeed([oldq.top, oldq.left], newq);-->

<!--      $('.ext-bg-overlay .div2').animate({top: newq[1], left: newq[1]}, speed, function () {-->
<!--        animateDiv2();-->
<!--      });-->

<!--    }-->

<!--    function calcSpeed(prev, next) {-->

<!--      var x = Math.abs(prev[1] - next[1]);-->
<!--      var y = Math.abs(prev[0] - next[0]);-->

<!--      var greatest = x > y ? x : y;-->

<!--      var speedModifier = 0.2;-->

<!--      var speed = Math.ceil(greatest / speedModifier);-->

<!--      return speed;-->

<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped lang="scss">-->
<!--body {-->
<!--  overflow: hidden!important;-->
<!--}-->
<!--.team-section-page {-->
<!--  display: block;-->
<!--  overflow: hidden!important;-->
<!--}-->

<!--.hero-team {-->
<!--  background-repeat: no-repeat;-->
<!--  background-size: cover;-->
<!--  background-position: 50%;-->
<!--  position: relative;-->
<!--}-->

<!--.romb {-->
<!--  transform: rotate(45deg);-->
<!--  -ms-transform: rotate(45deg);-->
<!--  -webkit-transform: rotate(45deg);-->
<!--  position: absolute;-->
<!--  border-radius: 20px;-->
<!--}-->

<!--.container-info-generic {-->
<!--  width: 80%;-->
<!--}-->

<!--.ext-bg-overlay {-->
<!--  background: transparent;-->
<!--  display: block;-->
<!--  overflow: hidden!important;-->
<!--}-->

<!--.ext-bg-overlay .div1 {-->
<!--  width: 15.75em;-->
<!--  height: 15.75em;-->
<!--  background-color: rgba(255, 255, 255, .3);-->
<!--  left: 75%;-->
<!--  top: 95%;-->
<!--}-->

<!--.ext-bg-overlay .div2 {-->
<!--  width: 20em;-->
<!--  height: 20em;-->
<!--  left: 100%;-->
<!--  top: 40%;-->
<!--  background-color: rgba(255, 255, 255, .1);-->
<!--}-->

<!--.ext-bg-overlay .div3 {-->
<!--  border-radius: 50%;-->
<!--  width: 3em;-->
<!--  height: 3em;-->
<!--  border: 1px solid  rgba(255, 255, 255, .5);-->
<!--  background-color: rgba(255, 255, 255, .8);-->
<!--  left: 65%;-->
<!--  top: 40%;-->
<!--}-->

<!--.ext-bg-overlay .div4 {-->
<!--  border-radius: 10%;-->
<!--  width: 7em;-->
<!--  height: 7em;-->
<!--  border: 3px solid #2d98c8;-->
<!--  left: 15%;-->
<!--  top: 40%;-->
<!--  background-color: rgba(255, 255, 255, .05);-->
<!--}-->

<!--.ext-bg-overlay .div5 {-->
<!--  border-radius: 5%;-->
<!--  width: 25em;-->
<!--  height: 25em;-->
<!--  border: 3px solid #2d98c8;-->
<!--  left: 0;-->
<!--  top: 40%;-->
<!--  background-color: rgba(255, 255, 255, .05);-->
<!--}-->

<!--.container-left {-->
<!--  display: -webkit-box;-->
<!--  display: -ms-flexbox;-->
<!--  display: flex;-->
<!--  -webkit-box-align: start;-->
<!--  -ms-flex-align: center;-->
<!--  align-items: flex-start;-->
<!--  -webkit-box-pack: start;-->
<!--  -ms-flex-pack: center;-->
<!--  justify-content: center;-->
<!--  -webkit-box-orient: vertical;-->
<!--  -webkit-box-direction: normal;-->
<!--  -ms-flex-direction: column;-->
<!--  flex-direction: column;-->
<!--  height: 100vh;-->
<!--  font-weight: 900;-->
<!--  padding-left: 40px;-->
<!--}-->

<!--.container-left-swipe {-->
<!--  position: relative;-->
<!--  width: fit-content;-->

<!--  &::after {-->
<!--    display: block;-->
<!--    content: '';-->
<!--    position: absolute;-->
<!--    top: 0;-->
<!--    left: 0;-->
<!--    right: 100%;-->
<!--    width: 0;-->
<!--    height: 100%;-->
<!--    background-color: #fafafa;-->
<!--    animation: swipe 1.5s ease-out 1s forwards;-->
<!--  }-->

<!--  &&#45;&#45;delay {-->
<!--    &::after {-->
<!--      background-color: #2d98c8;-->
<!--      animation-delay: 1.5s;-->
<!--    }-->
<!--  }-->
<!--}-->

<!--.container-left-swipe_title {-->
<!--  font-size: 7rem;-->
<!--  opacity: 0;-->
<!--  color: #fafafa;-->
<!--  animation: fade 0.01s ease-out 1.75s forwards;-->
<!--  font-family: "Heebo", sans-serif;-->
<!--}-->

<!--.container-left-swipe_subtitle {-->
<!--  font-size: 2.01rem;-->
<!--  color: #2d98c8;-->
<!--  opacity: 0;-->
<!--  animation: fade 0.01s ease-out 2.25s forwards;-->
<!--  text-shadow: 2.5px 2.5px 4px #000000;-->
<!--  font-family: "Heebo", sans-serif;-->
<!--}-->

<!--.container-left-swipe_title, .container-left-swipe_subtitle {-->
<!--  line-height: 1;-->
<!--  display: block;-->
<!--}-->
<!--.container-left-swipe_subtitle p {-->
<!--  margin-left: 11px;-->
<!--}-->

<!--@keyframes fade {-->
<!--  from {-->
<!--    opacity: 0;-->
<!--  }-->
<!--  to {-->
<!--    opacity: 1;-->
<!--  }-->
<!--}-->

<!--@keyframes swipe {-->
<!--  0% {-->
<!--    right: 100%;-->
<!--    left: 0;-->
<!--    width: 0%;-->
<!--  }-->
<!--  50% {-->
<!--    right: 0;-->
<!--    left: 0;-->
<!--    width: 100%;-->
<!--  }-->
<!--  100% {-->
<!--    right: 0;-->
<!--    left: 100%;-->
<!--    width: 0%;-->
<!--  }-->
<!--}-->


<!--@media only screen and (max-width: 1024px) {-->
<!--  .hero-team {-->
<!--    background-position: 96%;-->
<!--  }-->
<!--  .container-left-swipe_title {-->
<!--    font-size: 5.5rem;-->
<!--  }-->
<!--  .container-left-swipe_subtitle {-->
<!--    font-size: 1.55rem;-->
<!--  }-->
<!--}-->

<!--@media screen and (max-width: 768px) {-->
<!--  .container-left-swipe_title {-->
<!--    font-size: 4rem;-->
<!--  }-->
<!--  .container-left-swipe_subtitle {-->
<!--    font-size: 1.1rem;-->
<!--  }-->
<!--  .fullpage__content {-->
<!--    display: grid;-->
<!--    grid-template-columns: minmax(100%, 100%) auto;-->
<!--    position: relative;-->
<!--    height: 100%;-->
<!--  }-->
<!--}-->

<!--@media only screen and (max-width: 425px) {-->
<!--  .ext-bg-overlay {-->
<!--    display: none;-->
<!--  }-->
<!--  .container-left {-->
<!--    display: flex;-->
<!--    -webkit-box-align: center;-->
<!--    -ms-flex-align: center;-->
<!--    align-items: center;-->
<!--    -webkit-box-pack: center;-->
<!--  }-->
<!--  .container-info-generic {-->
<!--    width: 100%;-->
<!--  }-->
<!--  .container-left {-->
<!--    width: 100%;-->
<!--    justify-content: unset;-->
<!--    padding: 11vh 0;-->
<!--  }-->
<!--  .hero-team {-->
<!--    background-position: 88%;-->
<!--  }-->
<!--}-->

<!--@media only screen and (max-width: 375px) {-->
<!--  .hero-team {-->
<!--    background-position: 85%;-->
<!--  }-->
<!--}-->

<!--@media only screen and (max-width: 320px) {-->
<!--  .hero-team {-->
<!--    background-position: 83%;-->
<!--    transform: scale(1.10);-->
<!--  }-->
<!--}-->
<!--</style>-->
